<template>
  <b-container
    class="mb-5"
    fluid
  >
    <b-breadcrumb
      v-if="$route.meta.breadcrumb"
      :items="$route.meta.breadcrumb || []"
    />

    <b-card>
      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Source</label>
            <b-select
              v-model="filter.filter_source"
              :options="filterSources"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <div class="form-group">
            <label>Filter By Status</label>
            <b-select
              v-model="filter.status"
              :options="filterStatuses"
              @change="onFilterChanged"
            />
          </div>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label for="resigned_lga">Filter By Resigned LGA</label>
            <v-select
              id="resigned_lga"
              v-model="selected.resigned_lga"
              :options="list.resigned_lgas"
              label="full_name"
              :disabled="(parseInt(filter.show_resigned_lga) !== 1 ||(filter.status !== 'Pending' && filter.status !== 'Invalid'))"
              placeholder="-- Please Select LGA --"
            >
              <template #option="{ full_name }">
                <span>{{ full_name }}</span>
              </template>
              <template #no-options="">
                No Available LGA
              </template>
            </v-select>
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          sm="6"
        >
          <b-form-group>
            <label>Search String</label>
            <b-input
              v-model="tableSettings.filter"
              type="text"
              placeholder="Enter search string"
              debounce="1000"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
        >
          <b-form-checkbox
            id="show_resigned_lga"
            v-model="filter.show_resigned_lga"
            name="show_resigned_lga"
            value="1"
            unchecked-value="0"
            switch
            @change="onFilterChanged"
          >
            <strong>Show From Resigned LGA</strong>
          </b-form-checkbox>
          <br>
        </b-col>

        <b-col cols="12">
          <b-button
            class="mr-1"
            variant="primary"
            :disabled="buttonValidators"
            @click="state.selected ? onUnSelectAll() : onSelectAll()"
          >
            {{ selected.rawdata.length > 0 ? 'Uncheck Current' : 'Select Current' }}
          </b-button>

          <b-button
            :disabled="selected.rawdata.length < 1"
            variant="secondary"
            @click="onShowTransferData"
          >
            Transfer Data
          </b-button>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        hover
        responsive
        class="mt-2"
        :per-page="tableSettings.perPage"
        :current-page="tableSettings.currentPage"
        :items="myProvider"
        :fields="tableSettings.fields"
        :sort-by.sync="tableSettings.sortBy"
        :sort-desc.sync="tableSettings.sortDesc"
        :sort-direction="tableSettings.sortDirection"
        :filter="tableSettings.filter"
        :filter-included-fields="tableSettings.filterOn"
        show-empty
      >
        <template #cell(index)="data">
          {{ tableSettings.currentPage * tableSettings.perPage - tableSettings.perPage + (data.index + 1) }}
        </template>

        <template #cell(lga)="data">
          <div class="text-nowrap">
            {{ `${data.item.submitted_by?.first_name || ''} ${data.item.submitted_by?.last_name || ''}` }}
          </div>
        </template>

        <template #cell()="data">
          <div class="text-nowrap">
            {{ data.value }}
          </div>
        </template>

        <template #cell(selected)="row">
          <div class="text-nowrap">
            <div class="form-group">
              <b-form-checkbox
                v-model="selected.rawdata"
                :value="row.item.id"
                :disabled="buttonValidators"
              />
            </div>
          </div>
        </template>

      </b-table>

      <b-row>
        <b-col
          cols="12"
          sm="6"
        >
          <div class="w-100 w-sm-25 mb-2 sm-mb-2">
            <b-select
              v-model="tableSettings.perPage"
              :options="tableSettings.pageOptions"
              size="sm"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          sm="6"
          class="d-flex justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="tableSettings.currentPage"
            :total-rows="tableSettings.totalRows"
            :per-page="tableSettings.perPage"
            first-number
            last-number
            pills
            prev-text="Prev"
            next-text="Next"
            aria-controls="table"
          />
        </b-col>
      </b-row>

      <b-modal
        id="modal-transfer-data"
        scrollable
        no-close-on-backdrop
        title="Transfer Data"
        @ok="onConfirm"
      >
        <ValidationObserver ref="form">
          <form
            role="form"
            novalidate
          >
            <div class="col-12 mb-5">
              <ValidationProvider
                #default="{ errors }"
                name="agent"
                vid="agent"
                rules="required"
              >
                <b-form-group>
                  <label for="agents">Lead Agent</label>
                  <v-select
                    id="agents"
                    v-model="selected.agent"
                    :options="list.agents"
                    :class="`${errors.length > 0 ? 'is-invalid' : ''}`"
                    label="full_name"
                  >
                    <template #option="{ full_name }">
                      <strong>{{ full_name }}</strong>
                    </template>
                    <template #no-options="">
                      No Available Lead Agents
                    </template>
                  </v-select>
                  <div
                    v-if="errors.length > 0"
                    class="invalid-feedback"
                  >
                    <span>{{ errors[0] }}</span>
                  </div>
                </b-form-group>
              </ValidationProvider>
            </div>
          </form>
        </ValidationObserver>
        <template #modal-footer="{ ok, cancel }">
          <b-button
            variant="success"
            :disabled="state.busy"
            @click="ok()"
          >
            Submit
          </b-button>
          <b-button
            variant="danger"
            :disabled="state.busy"
            @click="cancel()"
          >
            Cancel
          </b-button>
        </template>
      </b-modal>

    </b-card>
  </b-container>
</template>

<script>
import { core } from '@/config/pluginInit'
import { SharedListService, AdminRawDataService, AxiosError } from '@/services'
import formatter from '@/mixins/formatter'

export default {
  name: 'AdminRawData',

  middleware: ['auth', 'admin'],

  metaInfo () {
    return {
      title: 'Raw Data'
    }
  },

  mixins: [formatter],

  data () {
    return {
      state: {
        busy: false
      },
      filter: {
        date_from: this.dateFormatter(Date.now()),
        date_to: this.dateFormatter(Date.now()),
        filter_source: 'All',
        status: 'All',
        show_resigned_lga: 0,
        resigned_lga: 'all'
      },
      list: {
        filter_source: [],
        agents: [],
        resigned_lgas: []
      },
      selected: {
        resigned_lga: [],
        agent: null,
        rawdata: []
      },
      taggingData: {
        agent: null,
        selected: []
      },
      options: {
        resigned_lgas: [],
        statuses: [
          { text: 'Pending', value: 'Pending' },
          { text: 'Invalid', value: 'Invalid' },
          { text: 'Processed', value: 'Processed' }
        ]
      },
      tableSettings: {
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        currentPage: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: '',
        filterOn: [],
        fields: [
          'index',
          'selected',
          { key: 'created_at', formatter: this.dateTimeFormatter, sortable: true },
          { key: 'first_name', sortable: true },
          { key: 'last_name', sortable: true },
          { key: 'mobile_number' },
          { key: 'status' },
          { key: 'lga', label: 'LGA Name' },
          { key: 'source.source_name', label: 'Source' },
          { key: 'cluster.cluster_name', label: 'cluster' },
          { key: 'area.area_name', label: 'area' },
          { key: 'province.province_name', label: 'province' },
          { key: 'municipality.municipality_name', label: 'municipality' },
          { key: 'status' },
          { key: 'remarks' },
          { key: 'updated_at', formatter: this.dateTimeFormatter }
        ]
      }
    }
  },
  computed: {
    filterSources () {
      return [{ value: 'All', text: 'All' }].concat(this.list.filter_source)
    },

    filterStatuses () {
      return [{ value: 'All', text: 'All' }].concat(this.options.statuses)
    },

    buttonValidators () {
      return (this.tableSettings.totalRows < 1 || (this.filter.status !== 'Pending' && this.filter.status !== 'Invalid') || this.selected.resigned_lga === null)
    }
  },

  watch: {
    'selected.resigned_lga' (value) {
      this.filter.resigned_lga = value?.id || 'all'
      this.onFilterChanged()
    },

    'filter.status' (value) {
      this.selected.rawdata = []
      this.state.selected = false
      this.selected.resigned_lga = null
      if (['Pending', 'Invalid'].includes(value)) {
        this.state.selected = true
      }
    },

    'filter.show_resigned_lga' (value) {
      if (parseInt(value) === 0) {
        this.selected.rawdata = []
        this.state.selected = false
        this.selected.resigned_lga = null
      }
    },

    'selected.rawdata' (value) {
      this.taggingData.selected = value
      if (value.length > 0) {
        this.state.selected = true
      } else {
        this.state.selected = false
      }
    },

    'selected.agent' (value) {
      this.taggingData.agent = value?.id || null
    }
  },

  mounted () {
    core.index()
    this.getSources()
    this.getLeadAgents()
    this.getResignedLeadAgents()
  },

  methods: {
    async myProvider (ctx) {
      try {
        const { data } = await AdminRawDataService.get(
          this.objectToUrl({
            page: ctx.currentPage,
            per_page: ctx.perPage,
            sort: ctx.sortBy,
            sort_desc: ctx.sortDesc,
            filter_source: this.filter.filter_source,
            filter_status: this.filter.status,
            filter_resigned_lga: this.filter.resigned_lga,
            filter_show_resigned_lga: this.filter.show_resigned_lga,
            filter_text: ctx.filter
          })
        )
        this.tableSettings.totalRows = data.total_rows
        return data.items
      } catch {
        return []
      }
    },

    async getSources () {
      await SharedListService.getSources().then(({ data }) => {
        this.list.filter_source = data.map(({ id, source_name }) => ({
          text: source_name,
          value: id
        }))
      })
    },

    async getLeadAgents () {
      await SharedListService.getLeadAgents().then(({ data }) => {
        this.list.agents = data
      })
    },

    async getResignedLeadAgents () {
      await SharedListService.getResignedLeadAgents().then(({ data }) => {
        this.list.resigned_lgas = data
      })
    },

    async onConfirm (bvModalEvt) {
      bvModalEvt.preventDefault()
      await this.$refs.form.validate().then(async success => {
        if (success) {
          this.$swal.fire({
            icon: 'question',
            title: 'Question',
            text: 'Are you sure you want to transfer the raw data in this lead generation agent?',
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Yes',
            cancelButtonColor: '#FF2929',
            showCancelButton: true,
            showLoaderOnConfirm: true,
            preConfirm: () => {
              return this.onFormPutSubmit()
            },
            allowOutsideClick: () => !this.$swal.isLoading()
          })
        } else {
          this.$bvModal.msgBoxOk('Oops! There were problem with your inputs.', {
            title: 'Validation Error',
            size: 'sm',
            buttonSize: 'sm',
            okVariant: 'danger',
            centered: true
          })
        }
      })
    },

    async onFormPutSubmit () {
      return new Promise(resolve => {
        this.state.busy = true
        AdminRawDataService.put(this.taggingData).then(({ data }) => {
          this.state.busy = false
          this.$bvModal.hide('modal-transfer-data')
          this.$swal.fire({
            icon: 'success',
            title: 'Successful',
            text: data.message,
            confirmButtonColor: '#06C270',
            confirmButtonText: 'Dismiss'
          })
            .then(() => {
              this.selected.rawdata = []
              this.state.selected = false
              this.$refs.table.refresh()
            })
        })
          .catch(error => {
            this.state.busy = false
            if (error instanceof AxiosError) {
              if (error.code === 422) {
                this.$refs.form.setErrors(error.message)
                resolve(error)
              }
            }
          })
      })
    },

    onShowTransferData () {
      this.selected.agent = null
      this.$bvModal.show('modal-transfer-data')
    },

    onSelectAll () {
      this.state.selected = true
      this.selected.rawdata = this.$refs.table.localItems?.map(item => {
        return item.id
      })
    },

    onUnSelectAll () {
      this.state.selected = false
      this.selected.rawdata = []
    },

    onFilterChanged () {
      this.$refs.table.refresh()
    }
  }
}
</script>
